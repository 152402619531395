import React, { useState, Component } from 'react';
import { UserAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';


const Header = () => {
  const handleSignup = async () => {
    navigate('/signup')
}
const handleSignin = async () => {
    navigate('/signin')
}
const [sbal, setBal] = useState('');
  const navigate = useNavigate();
    const { user } = UserAuth();
    try {
    if (user.displayName != null) {
      var dispaly = user.displayName
      var bob = "https://eu.ui-avatars.com/api/?name="+dispaly+"&background=56FF90&size=128&rounded=true";
        
        axios({
          method: 'GET',
          url: `https://api.kincode.net/get/account/balance?uid=${user.uid}`,
          withCredentials: false,
        }).then(res=>{
          const bal = res.data.balance
          console.log(bal)
          setBal(bal)
      })
      } else {
        var dispaly = user.email
      }
      var among =   <span
      class="rounded-full text-white bg-gray-600 font-semibold text-sm flex align-center cursor-pointer active:bg-gray-300 transition duration-300 ease w-max">
      <img class="rounded-full w-9 h-9 max-w-none" alt="A"
        src={bob} />
            <img class="rounded-full w-9 h-9 max-w-none" alt="A"
        src="https://cdn.kincode.net/dollar.png" />

      <span class="flex items-center px-3 py-2">
        {sbal}
      </span>
      <button class="bg-transparent hover focus:outline-none">
      </button>
    </span>

    } catch (e) {
        var dispaly = "Guest"
        var among = <button onClick={handleSignup} className="rounded-l inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase hover:bg-green-700 focus:bg-green-700 focus:outline-none focus:ring-0 active:bg-green-800 transition duration-150 ease-in-out">Sign Up</button>
        var among2 = <button onClick={handleSignin} className="rounded-r inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase hover:bg-blue-700 focus:bg-blue-700 focus:outline-none focus:ring-0 active:bg-blue-800 transition duration-150 ease-in-out">Sign In</button>
    }

      
    return (
        <nav class="font-sans flex flex-col text-center top sm:flex-row sm:text-left sm:justify-between py-4 px-6 bg-gray-900 shadow sm:items-baseline w-full">
          <div class="mb-2 sm:mb-0">
              <Link to={"/"}>
            <img alt="DollaMe Icon Link To Homepage" src="https://us-east-1.tixte.net/uploads/files.sightbot.xyz/DollaMe.svg" width={150}/>
              </Link>
          </div>
          <div>
              <div className="flex space-x-1 justify-center">
                  <div>
                      <button type="button"
                              className="inline-block px-10 py-5 bg-lime-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-lime-700 hover:shadow-lg focus:bg-lime-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-lime-800 active:shadow-lg transition duration-150 ease-in-out">Earn
                      </button>
                     </div>
                    <div>
                      <button type="button"
                              className="inline-block px-10 py-5 bg-lime-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-lime-700 hover:shadow-lg focus:bg-lime-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-lime-800 active:shadow-lg transition duration-150 ease-in-out">Withdraw
                      </button>
                  </div>
                  <div>
                      <button type="button"
                              className="inline-block px-10 py-5 bg-yellow-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-yellow-600 hover:shadow-lg focus:bg-yellow-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-700 active:shadow-lg transition duration-150 ease-in-out">Bonus Tokens
                      </button>
                  </div>
              </div>
          </div>

          <div class="flex items-center justify-right">

          <div class="inline-flex shadow-md hover:shadow-lg focus:shadow-lg" role="group">
      {among}
      {among2}
          </div>
          </div>
        </nav>
    );
}

export default Header;