import React  from "react";
import { useState } from "react";
import { Link,useNavigate } from 'react-router-dom';
import { UserAuth } from "../context/AuthContext";

const Signup = () => {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [username, setUsername] = React.useState("");
    const [error, setError] = React.useState("");
  const { google } = UserAuth();
  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    setError('')
    try {
      await google()
      
    } catch (e) {
      setError(e.message)
      console.log(e.message)
    }
  }
    const {createUser} = UserAuth();
    const { csetUsername } = UserAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        try {
            await createUser(email, password);
            await csetUsername(username);
            navigate("/account");
        }
        catch (err) {
            setError(err.message);
            console.log(err.message);
        }
    }
    return (
        <div className="max-w-[700px] mx-auto my-16 p-4">
            <div>
                <h1 className="text-3xl font-bold">Sign Up</h1>
                <p classname="text-3x1 font-bold">
                    Already have an account? <a href="/signin" className="text-blue-600 underline">Sign In</a>
                </p>
            </div>
            <br></br>
            <form onSubmit={handleSubmit}>
            <div className="flex flex-col py-2">
                <label className="py-2 font-medium">Username</label>
                <input onChange={(e) => {setUsername(e.target.value)}} className="border p-3" type="usernamne" required></input>
                </div> 
               <div className="flex flex-col py-2">
                <label className="py-2 font-medium">Email Adress</label>
                <input onChange={(e) => {setEmail(e.target.value);}} className="border p-3" type="email" required></input>
                </div> 
            <div className="flex flex-col py-2">
                <label className="py-2 font-medium">Password</label>
                <input onChange={(e) => {setPassword(e.target.value)}} className="border p-3" type="password" required></input>
                </div> 
            <button className="border border-blue-500 bg-blue-600 hover:bg-blue-500 w-full p-4 my-2 text-white">Sign Up</button>
            </form>
            <div className="text-red-500">{error}</div>
            <img onClick={handleGoogleSignIn} src='https://us-east-1.tixte.net/uploads/files.sightbot.xyz/btn_google_signin_dark_normal_web@2x.png' alt="Sign In With Google" className='cursor-pointer' width={200}></img>
        </div>
    );
}

export default Signup;