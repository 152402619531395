import React  from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
const Index = () => {
    const player = document.querySelector("wallet");


    return (

        <body className={"bg-slate-700"}>
        <br></br>
        <div className={"w-full h-full flex justify-center items-center"}>
            <h2 className={"text-3xl font-bold text-white"}>Earn ‌</h2><h2 className={"text-3xl font-bold text-lime-400"}>Money</h2>
        </div>
        <br></br>
        <div className={"w-full h-full flex justify-center items-center"}>
            <h2 className={"text-3xl font-bold text-white"}>By... Animation Here</h2>
        </div>
        <div className={"max-w-[500px] mx-auto my-16 p-4"}>

                <div className={"flex justify-center px-10 py-5 rounded-full bg-orange-400"}>
            <h2 className={"text-3xl font-bold text-white"}>$%%AMT%%% Already Earned!</h2>
        </div></div>
        <center>
        <div className={"max-w-[150px] h-auto flex justify-center items-center"}>

            <Player id="wallet" src="https://assets5.lottiefiles.com/packages/lf20_8btahzqu.json"  background="transparent" autoplay speed="1"></Player>
        </div></center>
        <Link to={"/signup"}>
        <div className={"max-w-[500px] mx-auto my-16 p-4 flex justify-center"}>
            <button className={"inline-block px-20 py-10 bg-green-500 text-white font-bold text-xxl leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"}>Sign Up</button>
        </div>
        </Link>
        <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js"
                integrity="sha384-oBqDVmMz9ATKxIep9tiCxS/Z9fNfEXiDAYTujMAeBAsjFuCZSmKbSSUnQlmh/jp3"
                crossOrigin="anonymous"></script>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js"
                integrity="sha384-cuYeSxntonz0PPNlHhBs68uyIAVpIIOZZ5JqeqvYYIcEL727kskC66kF92t6Xl2V"
                crossOrigin="anonymous"></script>
        </body>
    );

}

export default Index;