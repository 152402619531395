import { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  sendEmailVerification,
  signInWithPopup,
  updateEmail,
  updateProfile,
  GoogleAuthProvider
} from 'firebase/auth';
import { auth } from '../firebase';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const provider = new GoogleAuthProvider();

const UserContext = createContext();

export const AuthContextProdiver = ({children}) => {

    
 
    const [user, setUser] = useState({});

    const google = () => {
      signInWithPopup(auth, provider)
      .then((result) => {

        
        <Navigate replace to="/error-page" />
        window.location.replace('/account')
      }).catch((error) => {
        console.log(error);
      });
    }

    const emailVerify = () => {
      sendEmailVerification(auth.currentUser)
    }
    const changeEmail = (email) => {
      updateEmail(auth.currentUser, email)
    }
    const csetUsername = (username) => {
      updateProfile(auth.currentUser, {displayName: username})
    }
    const resetPassword = (email) => {
      sendPasswordResetEmail(auth, email)
    }
    const createUser = async (email, password) => {
        try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        fetch(`https://api.kincode.net/create/account?uid=${userCredential.user.uid}&email=${userCredential.user.email}`, {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json'
          },
        });
      } catch (error) {
        console.log(error);
      }}
      const logout = () => {
        return signOut(auth)
      };
      const signIn = (email, password) =>  {
        return signInWithEmailAndPassword(auth, email, password)
       }
      useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async(currentUser) => {
          if(currentUser != null ){
            console.log("User is signed in")
            let user = currentUser;
            let bal =0;
            axios({
              method: 'GET',
              url: `https://api.kincode.net/get/account/balance?uid=${user.uid}`,
              withCredentials: false,
            }).then(res=>{
              const bal = res.data.balance
              console.log(bal)
              return bal;
          })
          .catch(err=>{
              console.log(err);
          })

          let pending =0;
          console.log(bal)
          fetch(`https://api.kincode.net/get/account/balance/pending?uid=${user.uid}`, {
              method: 'GET',
              mode: 'no-cors',
              headers: {
                'Content-Type': 'application/json'
              },
            }).then(response => response.text())
            .then(text => pending = text)
            .catch((error) => { console.error(error); });
          console.log(pending)
          } else {
            console.log("User is signed out")
          }
          console.log(currentUser);
          setUser(currentUser);
          return currentUser;
        });
        return () => {
          unsubscribe();
        };
      }, []);
      return (
        <UserContext.Provider value={{ createUser, user, logout, signIn, google,emailVerify, changeEmail, csetUsername, useEffect, resetPassword }}>
          {children}
        </UserContext.Provider>
      );
}

export const UserAuth = () => {
    return useContext(UserContext);
  };