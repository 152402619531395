import React from 'react';
import Signin from './components/Signin';
import { Routes, Route } from "react-router-dom"
import Signup from './components/Signup';
import Account from './components/Account';
import Index from './components/Index';
import {AuthContextProdiver } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Header from './components/header';
import Earn from './components/Earn';
import PassReset from './components/PassReset';
import { inject } from '@vercel/analytics';



function App() {
  inject();
  return (
     
    <div className="App">
      


      <script src="https://challenges.cloudflare.com/turnstile/v0/api.js" async defer></script>
              
      <AuthContextProdiver>
      <Routes>
        
        <Route path='/' element={[<Header/>, <Index/>]} />
        <Route path='/signin' element={[<Header/>, <Signin />]} />
        <Route path='/signup' element={[<Header/>, <Signup />]} />
        <Route path='/earn' element={[<Header/>, <Earn />]} />
        <Route path='/reset-password' element={[<Header/>, <PassReset/>]} />
        <Route path='/account' element={[<Header/>, <ProtectedRoute>
          <Account />
        </ProtectedRoute> ]} />
      </Routes>
  
      </AuthContextProdiver>
    </div>
  );
}

export default App;
