import { render } from '@testing-library/react';
import { wait } from '@testing-library/user-event/dist/utils';
import React, { useState, Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import axios from 'axios';
const Account = () => {
  const { user, logout, emailVerify, changeEmail, useEffect } = UserAuth();
  const [email, setEmail] = useState('');
  const [sbal, setBal] = useState('');
  const [spending, setPending] = useState('');
  const navigate = useNavigate();
  const [info, setInfo] = useState('');
  console.log(useEffect)
  var bal = 0;
  var pending = 0;

  console.log("User is signed in")
  axios({
    method: 'GET',
    url: `https://api.kincode.net/get/account/balance?uid=${user.uid}`,
    withCredentials: false,
  }).then(res=>{
    const bal = res.data.balance
    console.log(bal)
    setBal(bal)
})
axios({
  method: 'GET',
  url: `https://api.kincode.net/get/account/balance/pending?uid=${user.uid}`,
  withCredentials: false,
}).then(res=>{
  const pending = res.data.pending
  console.log(pending)
  setPending(pending)
})

  const handleVerify = async () => {
    try {
      await emailVerify();
      setInfo('Email sent. Please check your inbox. Note: Check your spam folder if you do not see it in your inbox.');
      console.log('You are verified')
    } catch (e) {
      console.log(e.message);
    }
  };
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      console.log('You are logged out')
    } catch (e) {
      console.log(e.message);
    }

  };
  const handleEmailChange = async () => {
    try {
      await changeEmail(email);
      await emailVerify();
      setInfo('Email changed successfully.\nPlease verify your new email address.');
      console.log('Email changed successfully')
    } catch (e) {
      console.log(e.message);
    }

  };
  if (user.displayName != null) {
    var dispaly = user.displayName
  } else {
    var dispaly = user.email
  }
  if (user.emailVerified != true) {
    var verified = <button onClick={handleVerify} className='border border-blue-500 bg-blue-600 hover:bg-blue-500 p-2 my-2 text-white'>Verify Email</button>
  } else {
    var verified =  <button type="button" class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md focus:outline-none focus:ring-0 transition duration-150 ease-in-out pointer-events-none opacity-60" disabled>✅ Email Verified</button>
  }
  var bob = "https://eu.ui-avatars.com/api/?name="+dispaly+"&background=56FF90&color=000000&size=128?length=1";
  return (
 
    <body class="bg-slate-700"> 
    <div className='max-w-[600px] mx-auto my-16 p-4'>
      <h1 className='text-2xl font-bold py-4'>Account</h1>
      <p>User Email: {user && user.email}</p>
      {verified}
      {info && <h2 className='text-green-500'>{info}</h2>}
      {sbal && <h2 className='text-green-500'>Balance: {sbal}</h2>}
      {spending && <h2 className='text-yellow-500'>Pending: {spending}</h2>}
      <div>
      Hey, <img src={bob} alt="Profile" width={50}></img>
      </div>
    <div>
    </div>
      <button onClick={handleLogout} className='border px-6 py-2 my-4'>
        Logout
      </button>
    </div>
    </body>

  );
};

export default Account;