import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import Chart from 'chart.js/auto';
import axios from 'axios';
const Earn = () => {
    
  const [sbal, setBal] = useState('');


    const offerWallClick = async (e) => {
    }
    const { user, logout, emailVerify, changeEmail } = UserAuth();
    var bal = 0;
    var pending = 0;


    try {
  axios({
    method: 'GET',
    url: `https://api.kincode.net/get/account/balance?uid=${user.uid}`,
    withCredentials: false,
  }).then(res=>{
    const bal = res.data.balance
    console.log(parseInt(bal))
    setBal(parseInt(bal))
})
    } catch (e) {
        console.log(e.message);
    }
    try{
    if (user.displayName == null) {
        var dispaly = "Guest"
    } else {
        var dispaly = user.displayName
        var ADGEM = `https://api.adgem.com/v1/wall?appid=25687&playerid=${user.uid}`
    }
} catch (e) {
    console.log(e.message);
    var dispaly = "Guest"
}
    const navigate = useNavigate();
    const handleAyte = async () => {
        try {
            if(dispaly === "Guest") {
                navigate('/signup');
            } else {
                window.location.replace(`https://www.ayetstudios.com/offers/web_offerwall/10898?external_identifier=${user.uid}`)
            }
        }
        catch (e) {
            console.log(e.message);
        }
    }
    const login = document.getElementById('myChart');
    const nologin = document.getElementById('myChartm');
    try {
if(user.email != null) {
  if(sbal.isNaN) {
    console.log("NaN")
  } else {
    console.log("Not NaN")
  }
    new Chart(login, {
      type: 'doughnut',
      data: {
        labels: ['Active', 'Pending'],
        datasets: [{
          label: '# of Tokens',
          data: [sbal, 0],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            responsive: true,
          }
        }
      }
    });

}
    } catch (e) {
        console.log(e.message);

        }
    return (

        <body className='max-w-[900px] mx-auto my-16 p-4 bg-slate-700'>
            
            <div className="w-full h-full flex justify-center items-center">
        <h2 className="text-3xl font-bold text-white">Lets get earning, {dispaly}!</h2>
        </div>
        <center>

        <div>
            <br></br>
<br></br>
<div>

</div>
<button onClick={offerWallClick} className=" inline-block px-10 py-5 bg-green-600 text-white font-medium text-3-x1 leading-tight uppercase hover:bg-green-700 focus:bg-green-700 focus:outline-none focus:ring-0 active:bg-green-800 transition duration-150 ease-in-out">Offerwalls</button>
<button className="rounded-r inline-block px-10 py-5 bg-blue-600 text-white font-medium text-3-x1 leading-tight uppercase hover:bg-blue-700 focus:bg-blue-700 focus:outline-none focus:ring-0 active:bg-blue-800 transition duration-150 ease-in-out">Surveys</button>


  
        </div>
        
        <div>

</div>
<br></br>
        <div className='bg-slate-600 rounded-lg p-4'>
            <div className="h-16 w-full bg-black bg-opacity-50">
                <div className="w-full h-full flex justify-center items-center">
                    <div
                        className="flex h-full items-center  hover:bg-black hover:bg-opacity-50">
                        <div className="mx-4 text-white"><img src={"https://cdn.kincode.net/ADGEM_LOGO.png"} width={"100px"}/></div>
                        <div className=" h-8 w-px bg-gray-300"></div>
                    </div>
                    <div className="flex h-full items-center  hover:bg-black hover:bg-opacity-50">
                        <div className="mx-4 text-white">Coming Soon!</div>
                    </div>
                </div>
            </div>
        </div>
<br></br>


       <center><iframe src='https://cdn.kincode.net/no-ptn.htm' height={"600px"} width={"100%"}></iframe></center>


<script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
        </center>
    <script src="//unpkg.com/alpinejs" defer></script>
        <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js" integrity="sha384-oBqDVmMz9ATKxIep9tiCxS/Z9fNfEXiDAYTujMAeBAsjFuCZSmKbSSUnQlmh/jp3" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js" integrity="sha384-cuYeSxntonz0PPNlHhBs68uyIAVpIIOZZ5JqeqvYYIcEL727kskC66kF92t6Xl2V" crossorigin="anonymous"></script>
        </body>
        
        
    );
}

export default Earn;