import { useState } from "react";
import React  from "react";
import { UserAuth } from '../context/AuthContext';
import CFTurnstile from 'react-cf-turnstile';
const PassReset = () => {
    const [email, setEmail] = useState('');
    const [info, setInfo] = useState('');
    const [error, setError] = useState('');
    const { resetPassword } = UserAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setInfo('');
        if (email === '') {
            setError('Please enter an email address.');
            return;
        }
        try {
            await resetPassword(email);
            setInfo('If that email exists in our system, we have sent you an email with a link to reset your password.');
        } catch (e) {
            setError(e.message);
        }
    };
    return (


        <div class="min-h-screen bg-slate-700 flex flex-col justify-center sm:py-12">
        <div class="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
        <img src="https://us-east-1.tixte.net/uploads/files.sightbot.xyz/DollaMe.svg" alt="Dolla.ME BETA"></img>
          <div class="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
            <div class="px-5 py-7">
                <center>
                <h1 className="text-3xl font-bold">Reset Password</h1>
                </center>
              <form  onSubmit={handleSubmit} class="sign">
              <label class="font-semibold text-sm text-gray-600 pb-1 block">E-mail</label>
              
              
              
            <input onChange={(e) => setEmail(e.target.value)} type="email" class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" />
            <center>              <CFTurnstile
        sitekey="0x4AAAAAAABs2M_VN5P2yqWp" // change me! testing only
        action="FORM_SUBMIT" // optional: used in analytics to differentiate between different turnstiles on the same site
        callback={(token) => { // called when challenge is completed successfully
          console.log(token);
        }}
        expired-callback={() => { // optional: called when a challenge expires
          console.log('expired');
        }}
        error-callback={(error) => { // optional: called when an error occurs
            console.error(error);
        }}
        theme="light" // optional: light/dark/auto
        tabindex="1" // optional: tabindex for the iframe for accessibility
      />
      </center>
        <br></br>
              <button class="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block">
              <span class="inline-block mr-2">Reset Password</span>
             
      
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 inline-block">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
          </button>
                 
              
              </form>
              <br></br>
              {error && <h2 className='text-red-500'>{error}</h2>}
              {info && <h2 className='text-blue-500'>{info}</h2>}
            </div>
              <div className="py-5 align-middle">
        <div onClick={console.log("test")} className="text-center whitespace-nowrap">
                  <button
                      className="transition duration-200 mx-5 px-5 py-4 cursor-pointer font-normal text-sm rounded-lg text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ring-inset">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="GREEN"
                           className="w-4 h-4 inline-block align-text-top">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"/>
                      </svg>
                      <span className="inline-block ml-1">I Remember My Password</span>
                  </button>
                  </div>
              </div>
            </div>

      </div>


    
        </div>

    );
}

export default PassReset;